<template>
  <GenericModal :title="title" size="sm" :config="modalConfig" @modal-closed="modalClosed" :errorMessage="errorMessage">
    <template #openButton="{ openModal }">
      <span id="void-button">
        <BaseButton icon="ban" variant="danger-outline" @click.native="openModal" :disabled="disabled">
          Void Order
        </BaseButton>
      </span>
      <b-tooltip target="void-button" triggers="hover" v-if="disabled && reason">
        <span>{{ formatMessage(reason) }}</span>
      </b-tooltip>
    </template>
    <template #header>
      <div class="px-2 text-lg font-bold">{{ title }}</div>
    </template>
    <template>
      <div class="p-2">
        <div class="mb-4" v-if="!subscriptionId" v-html="voidDescription"></div>
        <div>
          <!-- Single Order -->
          <VoidOrderDetails
            :orders="[...order]"
            :enableSelect="false"
            :isSingleVoid="isSingleVoid"
            v-if="isSingleVoid"
          />

          <!-- Multiple Orders -->
          <VoidOrderDetails
            :orders="groupOrders"
            ref="voidOrderDetails"
            @onOrderSelected="setVoidableSelectedOrders"
            :isSingleVoid="isSingleVoid"
            v-if="isMultiVoid"
          />

          <!-- Goldrush Subscription -->
          <div v-if="subscriptionId">
            <div class="p-2">
              <div class="flex flex-row justify-between">
                <p class="font-bold mb-1">{{ `Subscription #${this.formatUuid(this.subscriptionId)}` }}</p>
                <p class="mb-0">{{ formatCurrency(totalAmount, { precision: 2, currency: currency }) }}</p>
              </div>
            </div>

            <hr class="border-gray-300 mb-7" />
            <GoldrushVoidView :subscriptionId="subscriptionId" @voidableOrders="setVoidableGoldrushOrders" />
          </div>
        </div>
      </div>
      <div class="p-2">
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group
            label-for="voided-comment"
            :invalid-feedback="veeErrors.first('voided-comment')"
            label-class="mb-0"
          >
            <template slot="label">Reason for Voiding: <span class="text-red-600 required">*</span></template>
            <b-form-input
              name="voided-comment"
              v-model="comment"
              v-validate="{ required: true, max: 120 }"
              :state="validateState('voided-comment')"
              aria-describedby="voided-comment-feedback"
              data-vv-as="comment"
              trim
            />
          </b-form-group>
        </b-form>
      </div>
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <div class="flex py-1">
        <BaseButton variant="secondary" @click.native="closeModalHandler(closeModal)" class="mr-2"> Cancel </BaseButton>
        <BaseButton
          variant="danger"
          :disabled="!canVoid || !comment"
          :loading="loading"
          @click.native="submit(closeModal)"
        >
          Void
        </BaseButton>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal.vue';
import GoldrushVoidView from '@/components/GoldrushVoidView.vue';
import VoidOrderDetails from '@/components/VoidOrderDetails.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import OrderServiceV2 from '@/lib/order-service-v2';

export default {
  props: {
    order: {
      type: Object
    },
    groupOrders: {
      type: Array
    },
    disabled: {
      type: Boolean
    },
    reason: {
      type: String
    }
  },
  components: {
    GenericModal,
    GoldrushVoidView,
    VoidOrderDetails,
    BaseButton
  },
  computed: {
    title() {
      if (this.subscriptionId) {
        return 'Void Goldrush Subscription';
      } else if (this.isMultiVoid) {
        return 'Void Orders';
      } else {
        return 'Void Order';
      }
    },
    voidDescription() {
      if (this.isSingleVoid) {
        return 'Are you sure you wish to void this order?';
      } else {
        return 'This order is part of a group. Select the orders within the group you wish to void below.';
      }
    },
    voidSuccessfulMessage() {
      if (this.subscriptionId) {
        return 'Subscription voided successfully';
      } else if (this.isMultiVoid) {
        return 'Order(s) voided successfully';
      } else {
        return 'Order voided successfully';
      }
    },
    totalAmount() {
      return this.subscriptionAmount ? this.subscriptionAmount : this.order.ticketPricing?.totalPriceCents / 100;
    },
    currency() {
      return this.order.currency.toUpperCase();
    },
    isMultiVoid() {
      return this.groupOrders ? this.groupOrders.length > 1 : false;
    },
    isSingleVoid() {
      return !this.isMultiVoid && !this.subscriptionId;
    }
  },
  data() {
    return {
      modalConfig: {
        showHeaderClose: true,
        showCloseModalButton: false
      },
      errorMessage: null,
      loading: false,
      subscriptionId: this.order.subscriptionId ? this.order.subscriptionId : null,
      subscriptionAmount: null,
      canVoid: false,
      voidableOrders: [],
      comment: null
    };
  },
  async mounted() {
    // If we have a subscriptionId, we need to get the subscription amount
    // If we have a single order, we can void it without further checks
    if (this.subscriptionId) {
      await this.getSubscriptionAmount();
    } else if (this.isSingleVoid) {
      this.canVoid = true;
      this.voidableOrders = [this.order.id];
    }
  },
  methods: {
    async getSubscriptionAmount() {
      try {
        this.subscriptionAmount = await OrderServiceV2.getSubscriptionAmount(this.subscriptionId);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    setVoidableSelectedOrders(selectedOrders) {
      this.voidableOrders = selectedOrders;
      this.canVoid = (this.voidableOrders || []).length > 0;
    },
    setVoidableGoldrushOrders(draws) {
      const { currentDraw, futureDraws } = draws;
      const allDraws = [...futureDraws, currentDraw];
      this.voidableOrders = allDraws.map((draw) => draw?.id);
      this.canVoid = true;
    },
    formatMessage: (message) => {
      let newMsg = message;
      if (typeof message === 'string') {
        newMsg = newMsg.replace(/-/g, ' ');
        newMsg = newMsg.charAt(0).toUpperCase() + newMsg.slice(1);
      }

      return newMsg;
    },
    async submit(closeModal) {
      this.loading = true;
      try {
        if (!this.canVoid) {
          return;
        }

        this.canVoid = false;

        await OrderServiceV2.voidOrders(this.voidableOrders, this.comment);

        this.loading = false;
        closeModal();

        // Emit a success message
        this.$emit('success', this.voidSuccessfulMessage);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
        this.loading = false;
      } finally {
        this.canVoid = true;
      }
    },
    closeModalHandler(closeModal) {
      if (this.isMultiVoid) {
        this.$refs.voidOrderDetails.clearCheckboxes();
      }
      closeModal();
    },
    modalClosed() {
      this.errorMessage = null;
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>
