import { retrieveNumericOrderType } from '@/lib/order-types';

// Note: This file should be kept in sync with OrderTypes in api
const OrderTypes = {
  // ALWAYS ADD NEW ORDER TYPES TO THE END TO KEEP numeric integer increasing.
  // NOTE: THERE IS NO ENUM = 0
  CUSTOMER_PAYMENTS: 'CUSTOMER PAYMENTS',
  CUSTOMER_REFUNDS: 'CUSTOMER REFUNDS',
  CUSTOMER_PAYOUTS: 'CUSTOMER PAYOUTS',
  ORGANIZATION_PAYOUTS: 'ORGANIZATION PAYOUTS',
  RAFFLEBOX_FEES: 'RAFFLEBOX FEES',
  // NOTE: GOLDRUSH POS PAYMENT CASH = 6
  GOLDRUSH_POS_PAYMENT_CASH: 'GOLDRUSH POS PAYMENT CASH',
  GOLDRUSH_POS_PAYMENT_CREDIT: 'GOLDRUSH POS PAYMENT CREDIT',
  GOLDRUSH_POS_REFUND_CASH: 'GOLDRUSH POS REFUND CASH',
  GOLDRUSH_POS_REFUND_CREDIT: 'GOLDRUSH POS REFUND CREDIT',
  GOLDRUSH_POS_SUBSCRIPTION_PAYMENT_CASH: 'GOLDRUSH POS SUBSCRIPTION PAYMENT CASH',
  GOLDRUSH_POS_SUBSCRIPTION_PAYMENT_CREDIT: 'GOLDRUSH POS SUBSCRIPTION PAYMENT CREDIT',
  GOLDRUSH_POS_SUBSCRIPTION_REFUND_CASH: 'GOLDRUSH POS SUBSCRIPTION REFUND CASH',
  GOLDRUSH_POS_SUBSCRIPTION_REFUND_CREDIT: 'GOLDRUSH POS SUBSCRIPTION REFUND CREDIT',
  // NOTE: GOLDRUSH ONLINE PAYMENT CASH = 14
  GOLDRUSH_ONLINE_PAYMENT_CASH: 'GOLDRUSH ONLINE PAYMENT CASH',
  GOLDRUSH_ONLINE_PAYMENT_CREDIT: 'GOLDRUSH ONLINE PAYMENT CREDIT',
  GOLDRUSH_ONLINE_REFUND_CASH: 'GOLDRUSH ONLINE REFUND CASH',
  GOLDRUSH_ONLINE_REFUND_CREDIT: 'GOLDRUSH ONLINE REFUND CREDIT',
  GOLDRUSH_ONLINE_SUBSCRIPTION_PAYMENT_CASH: 'GOLDRUSH ONLINE SUBSCRIPTION PAYMENT CASH',
  GOLDRUSH_ONLINE_SUBSCRIPTION_PAYMENT_CREDIT: 'GOLDRUSH ONLINE SUBSCRIPTION PAYMENT CREDIT',
  GOLDRUSH_ONLINE_SUBSCRIPTION_REFUND_CASH: 'GOLDRUSH ONLINE SUBSCRIPTION REFUND CASH',
  GOLDRUSH_ONLINE_SUBSCRIPTION_REFUND_CREDIT: 'GOLDRUSH ONLINE SUBSCRIPTION REFUND CREDIT',
  // NOTE: RAFFLE ONLINE PAYMENT CASH = 22
  RAFFLE_ONLINE_PAYMENT_CASH: 'RAFFLE ONLINE PAYMENT CASH',
  RAFFLE_ONLINE_PAYMENT_CREDIT: 'RAFFLE ONLINE PAYMENT CREDIT',
  RAFFLE_ONLINE_REFUND_CASH: 'RAFFLE ONLINE REFUND CASH',
  RAFFLE_ONLINE_REFUND_CREDIT: 'RAFFLE ONLINE REFUND CREDIT',
  // NOTE: RAFFLE HYBRID PAYMENT CASH = 26
  RAFFLE_HYBRID_PAYMENT_CASH: 'RAFFLE HYBRID PAYMENT CASH',
  RAFFLE_HYBRID_PAYMENT_CREDIT: 'RAFFLE HYBRID PAYMENT CREDIT',
  RAFFLE_HYBRID_REFUND_CASH: 'RAFFLE HYBRID REFUND CASH',
  RAFFLE_HYBRID_REFUND_CREDIT: 'RAFFLE HYBRID REFUND CREDIT',
  // NOTE: RAFFLE HYBRID PAYMENT CASH = 30
  RAFFLE_IN_VENUE_PAYMENT_CASH: 'RAFFLE IN VENUE PAYMENT CASH',
  RAFFLE_IN_VENUE_PAYMENT_CREDIT: 'RAFFLE IN VENUE PAYMENT CREDIT',
  RAFFLE_IN_VENUE_REFUND_CASH: 'RAFFLE IN VENUE REFUND CASH',
  RAFFLE_IN_VENUE_REFUND_CREDIT: 'RAFFLE IN VENUE REFUND CREDIT',
  // NOTE: RAFFLEBOX_BANK = 34
  RAFFLEBOX_BANK: 'RAFFLEBOX BANK',
  RAFFLEBOX_STRIPE: 'RAFFLEBOX STRIPE',
  STRIPE_FEES: 'STRIPE FEES',
  RAFFLE_ONLINE_PAYMENT_CHEQUE: 'RAFFLE ONLINE PAYMENT CHEQUE',
  // NOTE: RAFFLE_ONLINE_REFUND_CHEQUE = 38
  RAFFLE_ONLINE_REFUND_CHEQUE: 'RAFFLE ONLINE REFUND CHEQUE',
  RAFFLE_ONLINE_PAYMENT_MONEY_ORDER: 'RAFFLE ONLINE PAYMENT MONEY ORDER',
  RAFFLE_ONLINE_REFUND_MONEY_ORDER: 'RAFFLE ONLINE REFUND MONEY ORDER',
  RAFFLE_IN_VENUE_PAYMENT_DEBIT: 'RAFFLE IN VENUE PAYMENT DEBIT',
  // NOTE: RAFFLE_ONLINE_REFUND_CHEQUE = 42
  RAFFLE_IN_VENUE_REFUND_DEBIT: 'RAFFLE IN VENUE REFUND DEBIT',
  CUSTOM_ONLINE_PAYMENT_CREDIT: 'CUSTOM ONLINE PAYMENT CREDIT',
  PRIZE_WINNER_FREE: 'PRIZE WINNER FREE'
};

const formatOrderType = (value) => {
  if (value) {
    return OrderTypes[value];
  } else {
    return 'Unknown Order Type';
  }
};

const validateOrderType = (min, max, value) => {
  const numericOrderType = retrieveNumericOrderType(value);

  if (numericOrderType) {
    if (numericOrderType >= min && numericOrderType <= max) {
      return true;
    }
  }

  return false;
};

const isGoldrushPOSOrder = (value) => {
  return validateOrderType(6, 13, value);
};

const isGoldrushOnlineOrder = (value) => {
  return validateOrderType(14, 21, value);
};

const isRaffleOnlineOrder = (value) => {
  return validateOrderType(22, 25, value) || validateOrderType(37, 40, value);
};

const isRaffleHybridOrder = (value) => {
  return validateOrderType(26, 29, value);
};

const isRaffleInVenueOrder = (value) => {
  return validateOrderType(30, 33, value) || validateOrderType(41, 42, value);
};

export { isGoldrushOnlineOrder, isGoldrushPOSOrder, isRaffleOnlineOrder, isRaffleHybridOrder, isRaffleInVenueOrder };

export default formatOrderType;
